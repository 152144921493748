import React from "react";
import "./TestimonialCard.css";
import { Fade } from "react-reveal";
import { style } from "glamor";

export default function TestimonialCard({ repo, theme }) {
  // console.log(repo);
  // function openRepoinNewTab(url) {
  //   var win = window.open(url, "_blank");
  //   win.focus();
  // }

  // console.log(theme.testimonialName)

  const styles = style({
    color: "rgb(88, 96, 105)",
    backgroundColor: "rgb(255, 255, 255)",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 30px -15px",
    // padding: "2rem",
    cursor: "pointer",
    borderRadius: "5px",
    // height: "400px",
    transition: "all 0.2s ease-in-out",
    ":hover": {
      boxShadow: `${theme.imageDark} 0 2px 15px`,
    },
  });

  return (
    <div className="testimonial-wrapper">
      <Fade bottom duration={2000} distance="40px">
        <div
          {...styles}
          key={repo.id}
          // onClick={() => openRepoinNewTab(repo.url)}
          style={{ backgroundColor: theme.projectCard }
          }
          className="testimonial-card"
        >
          <div className="testimonial-name-div">
            <p className="testimonial-name" style={{ color: theme.testimonialName }}>
              {repo.name}
            </p>
          </div>
          <p className="testimonial-description" style={{ color: theme.text }}>
            {repo.testimonial}

            
          </p>
          <p className="testimonial-position" style={{ color: theme.secondaryText }}> {repo.position}</p>
          <div className="flexDiv">
            <div className="testimonial-details Leftitem">
              
            </div>
            <div className="testimonial-details Rightitem">
              
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
