import React from "react";
import Footer from "../../components/footer/Footer";
import TestimonialCard from "../../components/TestimonialCard/TestimonialCard";
import { Fade } from "react-reveal";
import { testimonialsHeader, testimonials } from "../../portfolio.js";
import "./Testimonials.css";
import TestimonialImg from "./TestimonialImg";
import 'bootstrap/dist/css/bootstrap.min.css';


function Testimonials(props) {
  const theme = props.theme;

  // const styles = style({
  //   backgroundColor: `${theme.accentBright}`,
  //   ":hover": {
  //     boxShadow: `0 5px 15px ${theme.accentBright}`,
  //   },
  // });

  return (
    <div id="testimonials" className="testimonials-main">
      <div className="basic-testimonials">
        <Fade bottom duration={2000} distance="40px">
          <div className="testimonials-heading-div">
            <div className="testimonials-heading-img-div">
              <TestimonialImg theme={theme} />
            </div>
            <div className="testimonials-heading-text-div">
              <h1
                className="testimonials-heading-text"
                style={{ color: theme.text }}
              >
                {testimonialsHeader.title}
              </h1>
              <p
                className="testimonials-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                {testimonialsHeader["description"]}
              </p>
            </div>
          </div>
        </Fade>
      </div>
      
      
          {testimonials.data.map((repo, index) => {
              return (
                <TestimonialCard key={index} repo={repo} theme={theme} />
              );
          })}

  
  
      <br />
      <br />
      <br />

      <br />
      <br />
      <Footer theme={props.theme} onToggle={props.onToggle} />
    </div>
  );
}

export default Testimonials;
